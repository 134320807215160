import { graphql } from 'gatsby';
import type { FC } from 'react';
import { PageFrame } from '../components/PageFrame';

type NotFoundProps = {
  data: GatsbyTypes.NotFoundQueryQuery;
};

export const NotFound: FC<NotFoundProps> = ({ data: { site } }) => {
  return (
    <PageFrame
      description={site?.siteMetadata?.description}
      title={site?.siteMetadata?.title}
    >
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </PageFrame>
  );
};
NotFound.displayName = 'NotFound';
export default NotFound;

export const query = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`;
